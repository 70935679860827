import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AbstractEntityService, BatchRequestService, HasUUID, HttpListResponseModel, Memoize } from '@roadrecord/utils';
import { isNil, isString } from '@roadrecord/type-guard';
import { UUID } from 'angular2-uuid';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnonymPartnerModel, LocationModel, PartnerListModel, PartnerModel } from '@roadrecord/partner/model';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { environment } from '@roadrecord/environment';

export interface PartnerLocationHttpListResponseModel extends HttpListResponseModel<LocationModel> {
  meta: {
    company_search: boolean;
  };
}

@Injectable()
export class PartnerService extends AbstractEntityService<HttpListResponseModel<PartnerListModel>, PartnerModel> implements HasUUID {
  readonly uuid: string;

  constructor(
    http: HttpClient,
    @Optional()
    @Inject('FALLBACK')
    endpoint = '',
    @Optional()
    @Inject('FALLBACK')
    defaultOrder = ''
  ) {
    if (isNil(endpoint) || endpoint.length === 0) {
      endpoint = 'partners';
    }
    if (isNil(defaultOrder) || defaultOrder.length === 0) {
      defaultOrder = 'name';
    }
    super(endpoint, defaultOrder, http);
    // memoize miatt kell egyedi azonosito az osztalynak
    this.uuid = UUID.UUID();
  }

  getToString(model: PartnerModel): string {
    return model.name;
  }

  getModelIdValue(model: PartnerModel): any {
    if (!isNil(model) && model.hasOwnProperty('id')) {
      return model.id;
    }
    return undefined;
  }

  @Memoize(5 * 60000)
  searchByPartnerNameOrAddress(nameOrAddress: string, headers?: { [key: string]: any }): Observable<PartnerLocationHttpListResponseModel> {
    if (headers === undefined) {
      headers = { search: nameOrAddress };
    } else {
      headers.search = nameOrAddress;
    }
    return this.http.get<PartnerLocationHttpListResponseModel>(`${environment.apiUrl}location/`, {
      params: headers,
    });
  }

  @Memoize(5 * 60000)
  getCoordinatesByAddress(locationModel: LocationModel): Observable<LocationModel> {
    return this.http
      .get<{ data: any }>(`${environment.apiUrl}location/hash/${locationModel.hash}`)
      .pipe(switchMap(location => of(location.data)));
  }

  get(id: number, params?: string): Observable<PartnerModel> {
    if (!isString(params)) {
      params = 'expand=trip_reason';
    } else {
      params += '&expand=trip_reason';
    }
    return super.get(id, params);
  }

  bulkEditTripReason(selectedPartners: PartnerModel[], trip_reason: number, batchRequestService: BatchRequestService): Observable<any[]> {
    batchRequestService.start();
    selectedPartners.forEach(partner =>
      batchRequestService.add<void>({
        method: 'patch',
        relative_url: `/${environment.baseApiEndpoint}/${this._entityUrl}/${partner.id}/`,
        body: JSON.stringify({ trip_reason }),
      })
    );
    return batchRequestService.end();
  }

  bulkEditType(selectedPartners: PartnerModel[], type: number, batchRequestService: BatchRequestService): Observable<any[]> {
    batchRequestService.start();
    selectedPartners.forEach(partner =>
      batchRequestService.add<void>({
        method: 'patch',
        relative_url: `/${environment.baseApiEndpoint}/${this._entityUrl}/${partner.id}/`,
        body: JSON.stringify({ type }),
      })
    );
    return batchRequestService.end();
  }

  getAll(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 1,
      pageSize: 99999999,
      length: 1,
    },
    simpleAllFilterValue: string = ''
  ): Observable<HttpListResponseModel<PartnerListModel>> {
    return this.http.get<HttpListResponseModel<PartnerListModel>>(
      `${this.getListOrResourceUrl(undefined, this.getUrlParameters(sort, page, simpleAllFilterValue))}&expand=trip_reason`
    );
  }

  /**
   *
   * @param sort
   * @param page
   * @param simpleAllFilterValue
   */
  getAnonymPartners(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 25,
      length: 1,
    },
    simpleAllFilterValue: string = ''
  ) {
    const queryParams = this.getUrlParameters(sort, page, simpleAllFilterValue);
    return this.http.get<{
      results: AnonymPartnerModel[];
    }>(`${environment.apiUrl}${this._entityUrl}/bulk/anonymous-rename/?${queryParams}`);
  }

  /**
   *
   * @param sort
   * @param page
   * @param simpleAllFilterValue
   */
  getAnonymAllPartners(
    sort: Sort = { active: '', direction: 'asc' },
    page: PageEvent = {
      pageIndex: 0,
      pageSize: 25,
      length: 1,
    },
    simpleAllFilterValue: string = ''
  ) {
    const queryParams = this.getUrlParameters(sort, page, simpleAllFilterValue);
    return this.http.get<{
      results: AnonymPartnerModel[];
    }>(`${environment.apiUrl}${this._entityUrl}/bulk/anonymous-rename/all/?${queryParams}&expand=trip_reason`);
  }

  saveAnonymPartners(value: { id: number; name: string; trip_reason_id: number }[]) {
    return this.http.put<{ errors: { id: number; error: string }[] }>(`${environment.apiUrl}${this._entityUrl}/bulk/anonymous-rename/`, {
      partners: value,
    });
  }

  saveAllAnonymPartners(value: { id: number; name: string; trip_reason_id: number }[]) {
    return this.http.put<{ errors: { id: number; error: string }[] }>(
      `${environment.apiUrl}${this._entityUrl}/bulk/anonymous-rename/all/`,
      {
        partners: value,
      }
    );
  }

  /**
   * Partnerek előfordulásának lekérdezése az utakban
   * @param partner_id {number}
   */
  getDestinationUsage(partner_id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}${this._entityUrl}/${partner_id}/destination-usage/`);
  }

  modifyTripReason(partner_id: number, trip_reason_id: number): Observable<HttpResponse<any>> {
    const payload = { trip_reason: trip_reason_id };
    return this.http.patch(`${environment.apiUrl}${this._entityUrl}/${partner_id}/`, payload, { observe: 'response' });
  }
}
