import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@roadrecord/grid';
import { BulkEditTripReasonBottomSheetModule } from './bulk-edit-trip-reason-bottom-sheet/bulk-edit-trip-reason-bottom-sheet.module';
import { BulkEditTypeBottomSheetModule } from './bulk-edit-type-bottom-sheet/bulk-edit-type-bottom-sheet.module';
import { ListComponent } from './list.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    BulkEditTripReasonBottomSheetModule,
    BulkEditTypeBottomSheetModule,
    MatIconModule,
    MatProgressBarModule,
    TranslocoModule,
    MatButtonModule,
  ],
  declarations: [ListComponent],
})
export class ListModule {}
