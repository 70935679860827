import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule } from '@roadrecord/grid';
import { PeriodicalListComponent } from './periodical-list.component';
import { StatusPanelComponent } from './status-panel/status-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { TextPlaceholderHtmlTagReplacerModule } from '@roadrecord/common/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    TranslocoModule,
    MatIconModule,
    FlexModule,
    MatButtonModule,
    MatExpansionModule,
    TextPlaceholderHtmlTagReplacerModule,
    MatTooltipModule,
  ],
  declarations: [PeriodicalListComponent, StatusPanelComponent],
  exports: [PeriodicalListComponent],
})
export class PeriodicalListModule {}
