<ng-template #bulkEditTripReasonBottomSheetRef>
  <rr-bulk-edit-trip-reason-bottom-sheet></rr-bulk-edit-trip-reason-bottom-sheet>
</ng-template>

<ng-template #bulkEditTypeBottomSheetRef>
  <rr-bulk-edit-type-bottom-sheet [type]="partnerType"></rr-bulk-edit-type-bottom-sheet>
</ng-template>
<rr-grid
  [multiSelection]="true"
  [hasCheckboxColumn]="true"
  [columnDefinitions]="displayedColumns"
  [hasIconColumn]="true"
  [titleText]="layoutTitle"
  [titleIcon]="titleIcon"
  [hasCommonActionsColumn]="true"
  [hasFilter]="true"
  [notEntitiesDataLabel]="gridNotEntitiesDataLabel"
  [notEntitiesDataLinkLabel]="gridNotEntitiesDataLinkLabel"
  [rightClickContextMenuOption]="rightClickContextMenuOption"
  [setIconFn]="setIconFn"
  [gridFilterLabel]="gridFilterLabel"
  [gridFilterPlaceholder]="gridFilterPlaceholder"
  [gridFilterHint]="gridFilterHint"
  [notEntitiesBackgroundImage]="notEntitiesBackgroundImage"
  [headerGroupActionDisabledLimit]="-1"
  [headerGroupActions]="headerGroupActions"
  [headerOtherActions]="headerOtherActions"
  #rrGrid
></rr-grid>

<ng-template #dialogStatusContentTpl>
  <table class="statusTable">
    <thead>
      <th>#</th>
      <th>{{ 'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.PROGRESS_FORM.TABLE.COLUMNS.NAME' | transloco }}</th>
      <th>{{ 'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.PROGRESS_FORM.TABLE.COLUMNS.TRIP_REASON_MODIFY' | transloco }}</th>
      <th *ngIf="tripReasonModifyType === partnerTripReasonModifyTypeEnum.TRIP_REASON_AND_EARLIER_TRIPS">
        {{ 'PARTNER.LIST.BULK_EDIT_TRIP_REASON_BOTTOM_SHEET.PROGRESS_FORM.TABLE.COLUMNS.EARLIER_TRIP_REASON_MODIFY' | transloco }}
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let partner of progressPartnerList; index as rowIndex">
        <td> {{ rowIndex + 1 }}. </td>
        <td>
          {{ partner.name }}
        </td>
        <td class="text-center">
          <mat-icon [ngStyle]="{ color: progressStatusConfig[partner.modifyPartnerTripReasonStatus]?.color }">
            {{ progressStatusConfig[partner.modifyPartnerTripReasonStatus]?.icon }}
          </mat-icon>
        </td>
        <td *ngIf="tripReasonModifyType === partnerTripReasonModifyTypeEnum.TRIP_REASON_AND_EARLIER_TRIPS" class="text-center">
          <span [innerHTML]="partner.modifyEarlierTripReasonDescription"></span>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #dialogStatusButtonsTpl>
  <div class="d-flex flex-column w-100 pb-2" style="gap: 10px">
    <div>
      <p class="text-center font-weight-bold"> {{ progress | number: '1.0-0' }} % </p>
      <mat-progress-bar mode="determinate" [value]="progress"> </mat-progress-bar>
    </div>
    <div>
      <button mat-raised-button color="accent" class="submit-button" [disabled]="processEnd" (click)="onClickCancelProcess()">
        <span>{{ 'COMMON.ACTION.CANCEL' | transloco }}</span>
      </button>

      <button mat-raised-button color="primary" class="submit-button" [disabled]="!processEnd" (click)="onClickCloseStatusDialog()">
        <span>{{ 'COMMON.ACTION.CLOSE' | transloco }}</span>
      </button>
    </div>
  </div>
</ng-template>
