import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MagicLinkModel, NavigateEventType, PriceItemModel } from '../model/models';
import { PriceStepEnum } from '../model/price-step.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isNil } from '@roadrecord/type-guard';
import { MagicLinkPath } from '../model/local-storage.enum';
import { Store } from '@ngxs/store';
import { FIRST_STEPS_PATH } from '@roadrecord/common/common';

@Component({
  selector: 'rr-price-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PriceListComponent {
  @Input()
  showInStepper = false;

  @Output() backToStepper = new EventEmitter<void>();

  @Input()
  loading: boolean;

  @Input()
  isBasicPrice = true;
  @ViewChild('priceDiv') firstDiv: ElementRef;
  metaGridColumnCount: string;
  readonly magicId: string;
  readonly magicUUID: string;
  readonly isStepperFinished = this.store.selectSnapshot(states => states.firstSteps.finished);
  @Output()
  navigateEvent = new EventEmitter<NavigateEventType>();
  maxFeatureListCount = 0;

  constructor(private route: ActivatedRoute, private router: Router, private store: Store, private ngZone: NgZone) {
    this.magicId = this.route.snapshot.queryParamMap.get('id');
    this.magicUUID = this.route.snapshot.queryParamMap.get('uuid');
  }

  private _priceList: PriceItemModel[] = [];
  gridTemplateColumns = 'repeat(auto-fit, minmax(280px, 280px))';

  @Input()
  get priceList() {
    return this._priceList;
  }

  set priceList(data: PriceItemModel[]) {
    this._priceList = data;

    if (data.length > 0) {
      this.setGridTemplateColumns(window.innerWidth, this.priceList.length);
      const maxFeatureListItem = this.priceList.reduce((prevItem, currentItem) => {
        return prevItem.feature_list.length > currentItem.feature_list.length ? prevItem : currentItem;
      });

      this.maxFeatureListCount = maxFeatureListItem.feature_list.length;
      setTimeout(() => {
        this.calculateMetaGridColumns();
      }, 200);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const screenWidth = (event.target as Window).innerWidth;
    this.setGridTemplateColumns(screenWidth, this.priceList.length);
    this.calculateMetaGridColumns();
  }

  /**
   * Csomag kiválasztása
   * @param selectedItemIndex {number}
   */
  onSelectedPackage(selectedItemIndex: number) {
    const selectedPackage = {
      ...this.priceList[selectedItemIndex],
    };

    if (!isNil(this.magicId) && !isEmpty(this.magicId) && !isNil(this.magicUUID) && !isEmpty(this.magicUUID)) {
      const magicData: MagicLinkModel = {
        id: this.magicId,
        uuid: this.magicUUID,
      };
      localStorage.setItem(MagicLinkPath.MAGIC_LINK_PATH, JSON.stringify(magicData));
    }

    this.navigateEvent.emit({ step: PriceStepEnum.ORDER_FORM, data: selectedPackage });
  }

  onBackToStepper(): void {
    if (this.showInStepper === true) {
      this.backToStepper.emit();
    } else if (this.isStepperFinished === false) {
      this.store.dispatch({ type: '[Menu] Restore Last State' }).subscribe(() => this.router.navigate(['/', FIRST_STEPS_PATH]));
    }
  }

  private calculateMetaGridColumns(): void {
    const columns = window.getComputedStyle(this.firstDiv.nativeElement).gridTemplateColumns.split(' ');
    this.metaGridColumnCount = `span ${columns.filter(column => column !== '0px').length}`;
  }

  private setGridTemplateColumns(screenWidth: number, length: number): void {
    const minMaxWidthValues = [437, 280];

    const minMaxWidth = minMaxWidthValues[+(length > 2 || screenWidth <= 768)];
    const columnSize = screenWidth > 768 ? length : 1;

    this.ngZone.run(() => {
      this.gridTemplateColumns = `repeat(${columnSize}, minmax(${minMaxWidth}px, ${minMaxWidth}px))`;
    });
  }
}
