import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecommendationRoutingModule } from './recommendation-routing.module';
import { RecommendationModule as InsideRecommendationModule } from './recommendation/recommendation.module';
import { SettingsModule } from './settings/settings.module';
import { PeriodicalListModule } from './settings/periodical-list/periodical-list.module';

@NgModule({
  imports: [CommonModule, RecommendationRoutingModule, InsideRecommendationModule, SettingsModule, PeriodicalListModule],
})
export class RecommendationModule {}
